<template>
    <header class="page-title">
        <h1><i class="bi bi-key-fill"></i> システム管理 - ユーザ管理</h1>
        <div>
            <button @click="sync()" class="btn btn-info me-3"><i class="bi bi-arrow-repeat"></i> Googleアカウント同期</button>
        </div>
    </header>

    <!-- 検索欄 -->
    <section class="section">
        <div>
            <form class="row align-items-end" @submit.prevent="search()">
                <div class="col-8">
                    <label class="form-label">氏名/ユーザID/メールアドレス</label>
                    <form-input v-model="condition.keyword"></form-input>
                </div>
                <div class="col-12">
                    <label class="form-label">部門/事業部</label>
                    <div class="input-group">
                        <form-select
                            v-model="condition.division"
                            :options="Division.options()"
                            empty_option="全て"
                            @selected="resetDepartment()"
                        ></form-select>
                        <form-select
                            v-model="condition.department"
                            :empty_option="condition.division === Division.FIELD_SALES ? '全て' : ''"
                            :options="options_department"
                            :disabled="condition.division === Division.FIELD_SALES ? false : true"
                        ></form-select>
                    </div>
                </div>
                <div class="col-4">
                    <label class="form-label">権限</label>
                    <form-select
                        v-model="condition.role"
                        :options="Role.options()"
                        empty_option="全て"
                    ></form-select>
                </div>
                <div class="col-4">
                    <label class="form-label">ログイン可否</label>
                    <form-select
                        v-model="condition.is_available"
                        :options="IsAvailable.options()"
                        empty_option="全て"
                    ></form-select>
                </div>
                <div class="col-4">
                    <label class="form-label">在籍状況</label>
                    <form-select
                        v-model="condition.enrolled"
                        :options="Enrolled.options()"
                        empty_option="全て"
                    ></form-select>
                </div>
                <div class="col-4">
                    <button type="submit" :disabled="loading_count > 0" class="btn btn-outline-primary"><i class="bi bi-search"></i> 検索</button>
                </div>
            </form>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <template v-else>
            <template v-if="users.length">
                <div class="mx-1 mb-3 px-3">
                    <p class="my-0">該当 : {{ users_total }} ユーザ</p>
                </div>
                <table class="table">
                    <thead>
                        <tr class="table-dark">
                            <th class="text-center col-3">氏名</th>
                            <th class="text-center col-4">GoogleユーザID</th>
                            <th class="text-center col-6">Googleメールアドレス</th>
                            <th class="text-center col-9">部門/事業部</th>
                            <th class="text-center col-3">権限</th>
                            <th class="text-center col-3">ログイン</th>
                            <th class="text-center col-6">chatwork ID</th>
                            <th class="text-center col-2"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="user in users" :key="user">
                            <tr>
                                <td class="align-middle">{{ user.user_name }}</td>
                                <td class="align-middle">{{ user.google_user_id }}</td>
                                <td class="align-middle">{{ user.google_email }}</td>
                                <td class="align-middle">
                                    <div class="input-group">
                                        <form-select
                                            v-model="user.division"
                                            :options="Division.options()"
                                            @selected="emptyDepartment(user)"
                                        ></form-select>
                                        <form-select-object
                                            v-model="user.department"
                                            :options="departments"
                                            option_value="department_id"
                                            option_label="department_name"
                                            :empty_option="user.division === Division.FIELD_SALES ? '-- 選択 --' : ''"
                                            :disabled="user.division == Division.FIELD_SALES ? false : true"
                                        ></form-select-object>
                                    </div>
                                </td>
                                <td class="align-middle">
                                    <form-select
                                        v-model="user.role"
                                        :options="Role.options()"
                                    ></form-select>
                                </td>
                                <td class="align-middle">
                                    <form-select
                                        v-model="user.is_available"
                                        :options="IsAvailable.options()"
                                    ></form-select>
                                </td>
                                <td class="align-middle">
                                    <form-input v-model="user.chatwork_account_id"></form-input>
                                </td>
                                <td class="align-middle">
                                    <button @click="update(user)" class="btn btn-info">保存</button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>

                <!-- ページネーション -->
                <paginator :meta="paginator" @move="search"></paginator>
            </template>

            <template v-else>
                <p class="alert alert-secondary">※該当ユーザがいません</p>
            </template>
        </template>

    </section>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import FormSelectObject from '@/components/forms/FormSelectObject';
import Paginator from '@/components/tools/Paginator';
import Division from '@/models/enums/division';
import Enrolled from '@/models/enums/enrolled';
import Role from '@/models/enums/role';
import IsAvailable from '@/models/enums/is-available';
import User from '@/models/entities/user';
import Department from '@/models/entities/department';

export default {
    name: 'SystemUser',
    components: {
        InlineLoader,
        FormInput,
        FormSelect,
        FormSelectObject,
        Paginator,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // 検索条件
            condition: {
                keyword: null,
                division: null,
                department: null,
                enrolled: Enrolled.ACTIVE,
                role: null,
                is_available: null,
                page: 1,
            },
            // 検索用option
            options_department: [],

            // ユーザ一覧
            users: [],
            users_total: null,

            // ページネーション
            paginator: {},

            // option一覧
            Division, // 部門
            Enrolled, // 在籍状況
            Role, // 権限
            IsAvailable, // ログイン可否
            departments: [], //事業部
        }
    },
    mounted() {
        this.fetchDepartments();
    },
    watch: {
        'condition.division'(new_value) {
            if (new_value === Division.NONE || new_value === Division.APPOINTMENT_TEAM) {
                this.condition.department = null;
            }
        }
    },
    methods: {
        // 事業部取得
        fetchDepartments() {
            this.loading_count++;

            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    // 検索用
                    this.options_department.push({value: department.department_id, label: department.department_name});
                    // 表示用
                    this.departments.push(department);
                }

                // 検索条件取得
                this.condition = this.$store.getters['condition/merge']('SystemUser', this.condition);
                this.search();
            })
            .finally(() => {
                this.loading_count--;
            });
        },
        // 検索
        search(page = 1) {
            this.loading_count++;
            this.condition.page = page;

            // リセット
            this.users.splice(0);

            // ユーザ取得
            this.$http.get('/system/user', {
                params: this.condition,
            })
            .then(response => {
                for (let row of response.data.data) {
                    this.users.push(new User(row));
                }

                // ページネーション用
                this.paginator = response.data.meta;
                this.users_total = response.data.meta.total;

                // 検索条件保存
                this.$store.commit('condition/setSearchCondition', {
                    page: 'SystemUser',
                    condition: this.condition,
                });
            })
            .finally(() => {
                this.loading_count--;
            })
        },
        // 保存
        update(user) {
            // 部門 が 2: 営業 以外は事業部(department)を空にする
            if (user.division !== Division.FIELD_SALES) {
                user.department = new Department();
            }

            this.startScreenLoading();

            this.$http.put(`/system/user/${user.user_id}`, user)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // Googleアカウント同期
        sync() {
            this.startScreenLoading();

            this.$http.post('/system/user/sync')
            .then(() => {
                this.showMessage('Googleアカウントに同期しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // 部門(division) が 営業のとき以外は事業部(department)を空にする
        emptyDepartment(user) {
            if (user.division !== Division.FIELD_SALES) {
                user.department = new Department();
            }
        },
        resetDepartment() {
            if (this.condition.division !== Division.FIELD_SALES) {
                this.condition.department = null;
            }
        },
    }
}
</script>

<style scoped>

</style>
