import Enum from './enum'

/**
 * 在籍状況
 */
class Enrolled extends Enum {
    static ACTIVE = 1; // 在籍中
    static RESIGNED = 9; // 退職済

    static values() {
        return {
            [this.ACTIVE]: '在籍中',
            [this.RESIGNED]: '退職済',
        }
    }
}

export default Enrolled;
